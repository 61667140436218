import classNames from 'classnames';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { getAssetUrl, loadScript } from '../../../utils';
import { ActionTypes } from '../../models/Analytics';
import { ArenaConfig } from '../../models/ArenaConfig';
import { Game } from '../../models/Game';
import { PrerollVariations } from '../../services/ABTestService';
import AdsService from '../../services/AdsService';
import { AnalyticsGamePage } from '../../services/Analytics/AnalyticsGamePage';
import { DeviceDetector } from '../../services/DeviceDetector';
import GemsService from '../../services/GemsService';
import { AppState } from '../../store/types';
import styles from './Preroll.css';
import { skipAdsButtonHandler } from './PrerollHelpers';

export enum PrerollType {
  PREROLL = 'pre-roll',
  REWARD = 'reward',
  INTERSTITIAL = 'interstitial',
  MIDROLL = 'mid-roll',
}

interface PrerollState {
  isCTAShowing: boolean;
  loadingPurchase: boolean;
}

interface PrerollProps {
  game: Game;
  config: ArenaConfig;
  prerollVariation: PrerollVariations;
  onEnd: () => void;
  prerollType?: PrerollType;
  isForMobileCrawling: boolean;
  stopMobileCrawlingRender: () => void;
  containerOnResize?: (ev: any, justForCta?: boolean | undefined) => void;
  dispatch: Dispatch;
  isPrerollSkipPriceLoading: boolean;
  prerollSkipPrice: number;
  arenaOrigin: string;
}

//noinspection TsLint
export const single_skippable_inline =
  'https://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/single_ad_samples&ciu_szs=300x250&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1&cust_params=deployment%3Ddevsite%26sample_ct%3Dskippablelinear&correlator=';

const PrerollElementId = 'ark_pre-roll';

class PrerollBase extends React.PureComponent<PrerollProps & WithTranslation> {
  prerollStartTime = 0;
  containerRef = React.createRef();
  state = {
    isCTAShowing: true,
    loadingPurchase: false,
  };
  wasPrerollRequestAnalyticsSent = false;

  setLoadingPurchase = (loadingPurchase: boolean) => {
    this.setState({ loadingPurchase });
  }

  getKeyValues() {
    const keyValues = [];

    return JSON.stringify(keyValues);
  }

  getCTAScreensConfig() {
    const { t } = this.props;

    return {
      containerClass: 'ctaContainer',
      colLeftClass: 'ctaColLeft',
      colRightClass: 'ctaColRight',
      messageBoxClass: 'ctaMessageBox',
      buttonClass: 'ctaButton',
      buttonArrowClass: 'ctaButtonArrow',
      buttonTextClass: 'ctaButtonText',

      messageText: t('VIDEO_ADS_BANNER_TEXT'),
      buttonText: t('VIDEO_ADS_BANNER_BUTTON_TEXT'),
    };
  }

  createSettingsForPreroll() {
    const {
      config,
    } = this.props;
    const {
      adUnit,
      product,
    } = config.ad;
    const isAutoPlay =
      this.props.prerollType === PrerollType.REWARD ||
      this.props.prerollType === PrerollType.INTERSTITIAL; /*prerollVariation === PrerollVariations.FIRST ||*/
    const adSettings: any = {
      adUnit: adUnit || undefined,
      width: 640,
      height: 480,
      element: PrerollElementId,
      autoPlay: isAutoPlay,
      adUrl: process.env.NODE_ENV === 'production' ? undefined : single_skippable_inline,
      type: this.props.prerollType || PrerollType.PREROLL,
      keyValues: this.getKeyValues(),
      product,
      mute: false,
    };

    if (!this.props.prerollType || this.props.prerollType === PrerollType.PREROLL) {
      adSettings.CTAScreenConfig = this.getCTAScreensConfig();

      adSettings.onCTAStateChange = ({ isShowing }: { isShowing: boolean }) => {
        this.setState({ isCTAShowing: isShowing });

        if (isShowing) {
          AnalyticsGamePage.prerollButton(ActionTypes.IMPRESSION);
        } else {
          AnalyticsGamePage.prerollButton(ActionTypes.CLICK);

          if (!this.wasPrerollRequestAnalyticsSent) {
            AnalyticsGamePage.prerollRequest();
            window.dispatchEvent(new Event('game:play')); // do not remove this (user click start btn)
          }
        }
      };
    }

    return adSettings;
  }

  findSkipAdButton = () => document.querySelector('.skipAdButton') as HTMLButtonElement;
  handleClickOnSkipAdButton = (e: Event) => {
    e.stopPropagation();
    const getActualProps = () => this.props;

    void skipAdsButtonHandler(this.props.dispatch, this.props.game, getActualProps(), this.setLoadingPurchase);
  }

  componentDidMount() {
    const {
      config,
      prerollVariation,
      dispatch,
    } = this.props;
    const videoAdPath = AdsService.getVideoAdPath(config.ad);
    const isRewardOrInterstitial =
      this.props.prerollType === PrerollType.REWARD || this.props.prerollType === PrerollType.INTERSTITIAL;
    const isAutoPlay = prerollVariation === PrerollVariations.FIRST || isRewardOrInterstitial;
    const thePrerollElement: any = this?.containerRef?.current;
    const stopMobileCrawlingRender = this?.props?.stopMobileCrawlingRender;
    const adSettings: any = this.createSettingsForPreroll();
    const isGemsSupport = config.isGemsSupport;
    const isEagle = config.isEagle;
    const findPlayButton = () =>
      document.querySelector(`.${adSettings?.CTAScreenConfig?.buttonClass || 'ctaButton'}`);
    const containerOnResize = this.props.containerOnResize;
    const onClick = this.handleClickOnSkipAdButton;

    function makeFullscreen() {
      stopMobileCrawlingRender();
    }

    function clickPlayButton() {
      makeFullscreen();
    }

    function handleCtaContainer(ev?: any) {
      containerOnResize?.(ev, true);
    }

    loadScript(videoAdPath).then(async (_) => {
      await GemsService.updatePriceSkipPreroll(dispatch);
      const {
        prerollSkipPrice,
        isPrerollSkipPriceLoading,
      } = this.props;

      if (thePrerollElement && stopMobileCrawlingRender) {
        const playButtonObserver = new MutationObserver(function () {
          const playButton = findPlayButton();

          if (thePrerollElement && playButton) {
            playButton.addEventListener('click', clickPlayButton);
            thePrerollElement.addEventListener('click', makeFullscreen);
            handleCtaContainer();
            window.addEventListener('resize', handleCtaContainer);
            const playButtonWrapper = playButton.parentNode;

            if (isEagle && isGemsSupport) {
              playButton.classList.add('gems');
              const skipAdButton = document.createElement('button');

              skipAdButton.addEventListener('click', onClick);
              skipAdButton.innerHTML = `
                            <span>Skip ad</span>
                            <div class="prerollPrice ${isPrerollSkipPriceLoading ? 'loading' : ''}">
                                <img src="${getAssetUrl()}/images/GemShopItem.png" alt='Skip Ad using gems'/>
                                <span>${prerollSkipPrice}</span>
                            </div>
                            <div class="prerollPriceLoader ${isPrerollSkipPriceLoading ? 'loading' : ''}">
                                <svg class="spinnerPreroll" role="img" aria-label="loader-img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 66">
                                    <circle class="pathPreroll" stroke="#08B238" fill="none" cx="33" cy="33" r="30"></circle>
                                </svg>
                            </div>`;
              skipAdButton.classList.add(...playButton.classList, 'skipAdButton');
              playButtonWrapper.appendChild(skipAdButton);
            }

            playButtonObserver.disconnect();
          } else {
            return console.log('NO PREROLL PLAY BUTTON / CTA CONTAINER!', playButton, thePrerollElement);
          }
        });

        playButtonObserver.observe(
          thePrerollElement,
          {
            subtree: true,
            childList: true,
          },
        );
      }

      const adVideo = new window.__ark_ads__.AdVideo();
      const adSettings: any = this.createSettingsForPreroll();

      adVideo.render(adSettings);
      this.prerollStartTime = Date.now();

      if (isAutoPlay) {
        AnalyticsGamePage.prerollRequest(this.props.prerollType);
        this.wasPrerollRequestAnalyticsSent = true;
      }
    });
  }

  componentDidUpdate(
    prevProps: Readonly<PrerollProps & WithTranslation>,
    prevState: Readonly<PrerollState>,
    snapshot?: any,
  ): void {
    if (prevState.loadingPurchase !== this.state.loadingPurchase) {
      const skipAdButton = this.findSkipAdButton();

      if (!skipAdButton) {
        return;
      }

      if (this.state.loadingPurchase) {
        skipAdButton.classList.add('loading');
        skipAdButton.removeEventListener('click', this.handleClickOnSkipAdButton);
        skipAdButton.innerHTML = `
                <div class="prerollPriceLoader loading">
                    <svg class="spinnerPreroll" role="img" aria-label="loader-img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 66">
                        <circle class="pathPreroll" stroke="#08B238" fill="none" cx="33" cy="33" r="30"></circle>
                    </svg>
                </div>`;
      } else {
        skipAdButton.classList.remove('loading');
        skipAdButton.addEventListener('click', this.handleClickOnSkipAdButton);
        skipAdButton.innerHTML = `
                    <span>Skip ad</span>
                    <div class="prerollPrice ${this.props.isPrerollSkipPriceLoading ? 'loading' : ''}">
                        <img src="${getAssetUrl()}/images/GemShopItem.png"  alt='Skip Ad using gems' />
                        <span>${this.props.prerollSkipPrice}</span>
                    </div>
                    <div class="prerollPriceLoader ${this.props.isPrerollSkipPriceLoading ? 'loading' : ''}">
                        <svg class="spinnerPreroll" role="img" aria-label="loader-img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 66">
                            <circle class="pathPreroll" stroke="#08B238" fill="none" cx="33" cy="33" r="30"></circle>
                        </svg>
                    </div>
                `;
      }
    }
  }

  render() {
    const {
      game,
      t,
      isForMobileCrawling,
    } = this.props;
    const { isCTAShowing } = this.state;
    const imageUrl = game.getThumbPath();
    const isFullscreen = DeviceDetector.isNotPc() && !isForMobileCrawling;

    return (
      <Container isFlexibleGame={game.isFlex} fullScreen={isFullscreen} ref={this.containerRef}>
        <style>
          {`
                        #${PrerollElementId} .ctaColLeft {
                            background-image: url("${imageUrl}");
                        }
                    `}
          {`@media(max-width:1199px){
                        #${PrerollElementId} .ctaButton {
                            bottom: 0px;
                            height: 64px;
                        }
                        #${PrerollElementId} .ctaButton:before {
                            bottom: 8px;
                        }
                        #${PrerollElementId} .ctaColLeft {
                            max-height: 65%;
                        }
                    }`}
          {`
                        @media (max-width: 767px) {
                            footer {
                                padding: 2.5rem 0 calc(2.5rem + 64px) 0 !important;
                            }
                        }
                    `}
        </style>

        {isCTAShowing ? null : <Message>{t('GAME_WILL_BEGIN', { gameName: game.name })}</Message>}

        <Player fullScreen={isFullscreen} />
      </Container>
    );
  }
}

const Container = React.forwardRef<any, any>(
  (
    {
      fullScreen,
      isFlexibleGame,
      isForMobileCrawling,
      ...props
    }: any,
    ref,
  ) => (
    <div
      ref={ref}
      className={classNames(styles.container, {
        [styles.fullScreen]: fullScreen,
      })}
      {...props}
    />
  ),
);
const Message = (props: any) => (
  <h4 className={styles.message} {...props}>
    {props.children}
  </h4>
);
const Player = ({
  fullScreen,
  ...props
}: any) => (
  <div id={PrerollElementId} className={classNames(styles.player, { [styles.fullScreen]: fullScreen })} {...props} />
);
const PrerollTranslated = withTranslation()(PrerollBase);

export const Preroll = connect((state: AppState) => ({
  prerollVariation: state.abTests.prerollVariation,
  isPrerollSkipPriceLoading: state.isPrerollSkipPriceLoading,
  prerollSkipPrice: state.prerollSkipPrice,
  gems: state.gems,
  arenaOrigin: state.arenaOrigin,
}))(PrerollTranslated);
